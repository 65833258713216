import React from "react";

const DataPoint = (props) => {

    return (
        <div className="w-full grid grid-cols-1 sm:grid-cols-8 gap-1 sm:gap-8  mb-6">
            <div className="sm:col-span-2"><p className="mb-0"><span className="font-bold">{props.title}:</span></p></div>
            <div className="sm:col-span-6">{props.children}</div>
        </div>
    );

}

export default DataPoint;