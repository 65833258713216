import React from 'react'
import PropTypes from "prop-types"

import styles from "./DetailBlock.module.scss"

const DetailBlock = (props) => {

    return (
        <div className="mb-4">
            <div className={` mx-auto ${styles.textbody}`} style={{maxWidth: props.wide ? "44rem" : "38rem"}}>
                <h2 className="mb-6">{props.title}</h2>
                {props.children}
            </div>
        </div>
    );

}

DetailBlock.propTypes = {
    image: PropTypes.string,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
  }

export default DetailBlock;