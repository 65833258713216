import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Divider from "../components/Divider/Divider"
import ImageBlock from "../components/ImageBlock/ImageBlock"
import DetailBlock from "../components/DetailBlock/DetailBlock"
import DataPoint from "../components/DataPoint/DataPoint"
import SEO from "../components/seo"

import Hyphenated from 'react-hyphen';

import {
  ReactCompareSlider
} from "react-compare-slider"

import Handle from "../components/Handle/Handle"

import styles from "./WorkPostTemplate.module.scss"

const shortcodes = { Divider, ImageBlock, DetailBlock, DataPoint }

const WorkPostTemplate = ({ data: { mdx } }) => {
  return (
    <Layout>
      <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.excerpt} />
      <div className="mt-16 mb-20">
      <Hyphenated><h1 className="">{mdx.frontmatter.title}</h1></Hyphenated>
        <p className="mb-16">{mdx.frontmatter.excerpt}</p>

        <ReactCompareSlider
          itemOne={
            
            <picture>
              <source srcSet={mdx.frontmatter.coverImages.beforeWEBP.publicURL} type="image/webp" />
              <source srcSet={mdx.frontmatter.coverImages.beforeJPG.publicURL} type="image/jpeg" /> 
              <img src={mdx.frontmatter.coverImages.beforeJPG.publicURL} alt="Alt Text!" />
            </picture>
            
          }
          itemTwo={
            <picture>
              <source srcSet={mdx.frontmatter.coverImages.afterWEBP.publicURL} type="image/webp" />
              <source srcSet={mdx.frontmatter.coverImages.afterJPG.publicURL} type="image/jpeg" /> 
              <img src={mdx.frontmatter.coverImages.afterJPG.publicURL} alt="Alt Text!" />
            </picture>
          }
          className="w-full"
          boundsPadding="2"
          position="30"
          handle={<Handle />}
        />
      </div>

      <div className="mx-auto">
        <DetailBlock title="The Low Down" wide>

          <DataPoint title="My Role">
            <p className="font-semibold mb-1">
              {mdx.frontmatter.lowdown.role.title}
            </p>
            <p>{mdx.frontmatter.lowdown.role.details}</p>
          </DataPoint>
          <DataPoint title="What I Did">
            <ul className="list-none ml-0">
              {mdx.frontmatter.lowdown.responsibilities.map(listItem => (
                <li className="ml-0" key={listItem}>{listItem}</li>
              ))}
            </ul>
          </DataPoint>
          <DataPoint title="Time">
            <p>{mdx.frontmatter.lowdown.time}</p>
          </DataPoint>
          <DataPoint title={mdx.frontmatter.lowdown.fun.title}>
            <p>{mdx.frontmatter.lowdown.fun.details}</p>
          </DataPoint>
        </DetailBlock>
      </div>

      <Divider />

      <div className={`mx-auto ${styles.detail}`}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query WorkPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        excerpt
        lowdown {
          fun {
            details
            title
          }
          responsibilities
          role {
            details
            title
          }
          time
        }
        coverImages {
          beforeJPG {
            publicURL
          }
          beforeWEBP {
            publicURL
          }
          afterJPG  {
            publicURL
          }
          afterWEBP  {
            publicURL
          }
        }
      }
    }
  }
`

export default WorkPostTemplate
