import React from "react"
import styles from "./Handle.module.scss"
import ScrollAnimation from "react-animate-on-scroll"
import SVGIcon from "../SVGIcon/SVGIcon"


const Handle = () => {
  return (
    <div className="h-full flex w-1 bg-white shadow-md text-center items-center relative cursor-pointer justify-center  ">
      <ScrollAnimation
        animateIn={styles.pulse}
        duration={1.5}
        className={`self-center absolute w-12 h-12 bg-primary-black hover:bg-primary-hover circle rounded-full border-2 shadow-md border-solid border-white flex text-center items-center p-2`}
        animateOnce
      >
        <SVGIcon icon="handle" width="27" height="16"/>
      </ScrollAnimation>
    </div>
  )
}

export default Handle
